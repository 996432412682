* {
  padding: 0;
  margin: 0
}

.login {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  margin-left: -200px;
  margin-top: -200px;
}

.login h1 {
  text-align: center;
  margin: 20px 0;
}

.login .ant-input-group-addon {
  padding: 0 !important;
}

.login img {
  height: 36px;
}

.errorMsg {
  text-align: center;
  color: #f00;
  line-height: 50px
}

.main-view {
  margin: 20px 15px;
  padding: 20px 10px;
  background: #fff;
}

.main-info {
  text-align: center;
  padding: 50px 30px;
}

.main-info .title {
  margin-bottom: 18px;
  font-size: 36px;
  font-weight: bold;
}

.main-info .info-text {
  margin-bottom: 30px;
  font-size: 28px;
}


body {
  a:hover {
    text-decoration: none;
  }

  textarea, input, select {
    border: 0;
    outline: none;
  }


  .fr {
    float: right;
  }

  .fl {
    float: left;
  }

  .clear {
    display: block;
    height: 0;
    clear: both;
    font-size: 0;
    line-height: 0;
    zoom: 1;
  }

  .o {
    overflow: hidden;
  }

  .h {
    display: none !important;
  }

  body {
    background: #f4f5f5;
  }

  .row {
    margin: 0;
  }

  .phpdebugbar-restore-btn {
    display: none !important;
  }
}
