.layout {
  min-height: 100vh;

  .menu-header {
    height: 64px;
    line-height: 64px;
    background: rgba(0, 40, 77, 1);
    text-align: center;
    color: #fff;
    font-size: 20px;
  }

  .header {
    background: #fff;
    padding: 0 10px 0 25px;

    header {
      text-align: right;

      .title {
        float: left;
        font-size: 24px;
        font-weight: bold;
      }

      .username{
        font-size: 16px;
        padding-right: 16px;
      }
    }
  }

}
